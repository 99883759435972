import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ApiService } from './api.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

	activateSub: Subscription;
	statusSub: Subscription;
	instanceState: string = '';
	instanceStatus: string = '';
	systemStatus: string = '';
	// @ViewChild("activate") activateBtn!: ElementRef;
	// @ViewChild("deactivate") deactivateBtn!: ElementRef;
	@ViewChild("loading") loadingDiv!: ElementRef;
	@ViewChild("success") successDiv!: ElementRef;
	@ViewChild("error") errorDiv!: ElementRef;

	constructor(private dataService: ApiService, private renderer: Renderer2, private titleService: Title) { }
	
	ngAfterViewInit() {
		this.titleService.setTitle('Detecta Europa');
	}

	ngOnDestroy() {
		this.activateSub.unsubscribe();
		this.statusSub.unsubscribe();
	}
	
	activate(event: MouseEvent) {
		let button = event.target as HTMLButtonElement;
		button.disabled = true;

		this.renderer.addClass(this.successDiv.nativeElement, 'hidden');
		this.renderer.addClass(this.errorDiv.nativeElement, 'hidden');
		this.renderer.removeClass(this.loadingDiv.nativeElement, 'hidden');

		this.activateSub = this.dataService.activate().subscribe(
			activateRes => {
				console.log(activateRes);

				/*
				while (this.instanceState != 'running' && this.instanceStatus != 'ok' && this.systemStatus != 'ok')
				{

					this.statusSub = this.dataService.status().subscribe(
						statusData => {
							console.log(statusData);
							let instanceStatuses = statusData.InstanceStatuses;
							if (instanceStatuses[0]) {
								// console.log(instanceStatuses[0]);
								this.instanceState = instanceStatuses[0]['InstanceState']['Name'];
								this.instanceStatus = instanceStatuses[0]['InstanceStatus']['Status'];
								this.systemStatus = instanceStatuses[0]['SystemStatus']['Status'];
								console.log(this.instanceState);
								console.log(this.instanceStatus);
								console.log(this.systemStatus);
							}
							// this.statusData = statusData;
							this.renderer.addClass(this.loadingDiv.nativeElement, 'hidden');
							this.renderer.removeClass(this.successDiv.nativeElement, 'hidden');
							// window.location.href = 'http://35.181.121.25:8889';
							// window.location.href = 'http://15.236.102.221/login.php';
						},
						statusError => {
							console.log(statusError);
							this.renderer.addClass(this.loadingDiv.nativeElement, 'hidden');
							this.renderer.removeClass(this.errorDiv.nativeElement, 'hidden');
						}
					)

				}
				*/

				// button.disabled = false;
				setTimeout(() => {
					this.renderer.addClass(this.loadingDiv.nativeElement, 'hidden');
					this.renderer.removeClass(this.successDiv.nativeElement, 'hidden');
					window.location.href = 'https://app.detecta-project.eu/login.php';
				}, 40000);

			},
			activateError => {
				button.disabled = false;
				console.log(activateError);
				this.renderer.addClass(this.loadingDiv.nativeElement, 'hidden');
				this.renderer.removeClass(this.errorDiv.nativeElement, 'hidden');
			}
		)
	}
}