<nav id="navbar" class="push better-nav fixed-top shadow">
	<div class="container">
		<div class="head">
			<a href="#" class="brand">
				<div class="logo">
					<img class="image" src="assets/img/logodetecta.png" alt="Detecta">
				</div>
			</a>
		</div>
	</div>
</nav>

<div class="body container shadow">

	<div class="container center">
		<button class="btn btn-success" (click)="activate($event)">Access to the application</button>
	</div>
	
	<div class="container center pt-3" style="height: 30px;">
		<div #loading class="hidden">
			<i class="fa fa-spinner fa-spin mr-1"></i>
			Connecting to the server and loading the application. Please, wait a moment...
		</div>
		
		<div #success class="hidden green">
			<i class="fa fa-check mr-1"></i>
			Connected. Redirecting...
		</div>
		
		<div #error class="hidden red">
			<i class="fa fa-times mr-1"></i>
			An error occurred. Please contact the administrator.
		</div>
	</div>

	<div class="container center">
		<img class="logos" src="assets/img/logos.jpg" alt="Logos">
	</div>
</div>
