import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	statusUrl: string = "https://4x2y889ikc.execute-api.eu-west-3.amazonaws.com/status";
	activateUrl: string = "https://w85f1q6qk8.execute-api.eu-west-3.amazonaws.com/activate";

	constructor(private httpClient: HttpClient) { }

	public status() {
		return this.httpClient.get<any>(this.statusUrl);
	}
	
	public activate() {
		return this.httpClient.get<any>(this.activateUrl);
	}
}
